.TargetsModal {
  width: 800px;
  background: white;
  padding: 30px 0;
  border-radius: 16px;
  box-sizing: border-box;

  .header {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    display: flex;
    margin: 0 30px;
    margin-bottom: 30px;

    button {
      width: 30px;
      height: 30px;
      background: #fbfbfb;
      border-radius: 15px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  .labels {
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    grid-column-gap: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(232, 234, 233, 0.7);
    color: #88939d;
    font-size: 14px;
    margin: 0 30px;
  }

  .targets {
    height: 550px;
  }
}

.Target {
  display: grid;
  grid-template-columns: 200px 1fr 1fr;
  grid-column-gap: 20px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(232, 234, 233, 0.7);

  &:last-of-type {
    border-bottom: none;
  }

  .name {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    margin-right: auto;
    text-transform: capitalize;

    .color,
    img {
      width: 18px;
      height: 18px;
      border-radius: 18px;
      margin-right: 10px;
    }
  }

  .values.range {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}

.footer {
  padding-top: 30px;
  margin: 0 30px;
  border-top: 1px solid rgba(232, 234, 233, 0.7);
}

.save {
  width: 440px;
  margin: 0 auto;
}
