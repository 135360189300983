.DailyDeltasChart {
  cursor: pointer;
  height: 62px;
}

.plot {
  fill: #f8f9fc;
}

.delta {
  stroke-width: 6px;
  stroke-linecap: round;
}
