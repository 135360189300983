.Analytics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 40px 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 50px;
  padding: 0 30px;
  padding-bottom: 30px;
}

.header {
  position: relative;
  margin-bottom: 30px;
  grid-column: 1 / 4;
}

.periodCard {
  position: absolute;
  width: 285px;
  box-sizing: border-box;
  padding: 25px 20px;

  .period {
    font-size: 16px;
    line-height: 19px;
    padding: 5px 0;
    cursor: pointer;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 15px;
}

.foodlog {
  grid-column: 1 / 4;
}
