.PastMassMessage {
  padding: 20px 20px 15px;
  width: 450px;
  background: #373c40;
  border-radius: 20px;
  margin-bottom: 20px;
}

.text {
  white-space: pre-wrap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 5px;
}

.footer {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #88939d;
  text-align: right;
}
