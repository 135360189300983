@import 'colors';

.Group {
  flex: 1;
  height: 100vh;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr;
}

.header {
  display: flex;
  align-items: center;
  padding: 50px;
  padding-bottom: 0;
  margin-bottom: 30px;

  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-right: 10px;
  }

  .edit {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .actions {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 10px;
    margin-left: auto;

    button {
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 40px;
      letter-spacing: -0.01em;
    }
  }

  .searchContainer {
    width: 200px;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 20px;
    padding-left: 15px;
    padding-right: 10px;
    grid-column-gap: 10px;
    align-items: center;
    box-sizing: border-box;
    background: #f8f9fc;
    border-radius: 10px;
  }

  .search {
    width: 100%;
    padding-right: 0;
    background: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    border: none;
    outline: none;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.clientsTable {
  height: calc(100vh - 120px);
  display: grid;
  grid-template-rows: auto 1fr;
  padding-left: 50px;
  padding-right: 50px;

  .tableHeader,
  .ClientRow {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 5fr 1fr;
    grid-column-gap: 10px;
    border-bottom: 1px solid #eff1f0;
  }

  .tableHeader {
    font-size: 12px;
    line-height: 15px;
    color: #88939d;
    padding-bottom: 15px;

    .col {
      span {
        display: flex;
        align-items: center;
        cursor: pointer;

        .invert {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  .tableBody {
    overflow-y: auto;
    padding-bottom: 50px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .ClientRow {
    height: 50px;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #88939d;
  }

  .col:nth-of-type(4) {
    text-align: right;
    display: flex;
    justify-content: flex-end;

    span {
      text-align: right;
    }
  }

  .open {
    display: flex;
    justify-content: flex-end;

    a {
      display: flex;
      align-items: center;
    }

    img {
      cursor: pointer;
    }
  }

  .ClientRow .col:first-of-type {
    color: $black;
    text-transform: capitalize;
  }
}

.emptyContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
