.modal {
  z-index: 200;
}

.card {
  width: 600px;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;

  > div:first-of-type {
    padding: 30px;
  }
}

.RecipientsModal {
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
}

.search {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 0;
}

.clients {
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 30px;
}

.allClientsContainer {
}

.avatar {
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #eff1f0;
  border-radius: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
  text-transform: uppercase;
}
