.WeekSelector {
  display: flex;
  position: relative;
}

.weekButton {
  margin-right: auto;
}

.calendar {
  position: absolute;
  top: 45px;
}

.weekDay,
.weekStart,
.weekEnd {
  background: #f8f9fc;
}

.weekStart {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.weekEnd {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.scrollButtons {
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #1c1e21;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;

  button {
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid rgba(232, 234, 233, 0.7);
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
      background: #fafafb;
    }

    &:nth-child(2) {
      padding: 0 12px;
    }
  }
}
