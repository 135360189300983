@import 'mixins';
@import 'colors';

.Main {
  flex: 1;
  display: flex;
}

.sidebar {
  flex-basis: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #212123;
}

.branding {
  width: 40px;
  height: 40px;
  margin-top: 30px;
  margin-bottom: auto;
}

.nav {
  display: grid;
  grid-template-rows: 44px 44px 44px;
  grid-row-gap: 26px;

  .navLink,
  .activeNav {
    position: relative;
    padding: 10px;
    border-radius: 15px;
    margin: 0 auto;

    img {
      width: 24px;
      height: 24px;
      opacity: 0.6;
    }
  }

  .activeNav {
    background: rgba(255, 255, 255, 0.1);

    img {
      opacity: 1;
    }
  }

  .unread {
    position: absolute;
    top: -4px;
    right: -4px;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
  }
}

.profileButton {
  position: relative;
  margin-top: auto;
  margin-bottom: 75px;

  button {
    width: 40px;
    height: 40px;
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}

.profileMenu {
  width: 350px;
  position: absolute;
  bottom: 0;
  left: 50px;

  .header {
    margin: 30px 30px 10px;
    display: flex;
    align-items: center;

    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      background: $gray;
      color: $white;
      margin-right: 10px;
      border: 1px solid rgba(232, 234, 233, 0.7);
      box-sizing: border-box;

      img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
      }
    }

    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      text-transform: capitalize;
    }
  }

  .bio {
    padding: 0 30px;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 54px;
      padding: 0 30px;
      border-radius: 0;
      color: $black;
      opacity: 0.5;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      &:hover {
        background: #f8f9fc;
        opacity: 1;
      }
    }
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
