.MassMessages {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 80px;
  background: white;
  border-bottom: 1px solid #edeef1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;

  .numActiveMessages {
    margin-left: 5px;
    color: #88939d;
  }
}

.messages {
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: auto;
}

.footer {
  padding: 20px;
  display: flex;
  justify-content: center;

  button {
    width: 440px;
  }
}
