.ScrollList {
  position: relative;
}

.contentContainer {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.content {
  padding: 0 30px;
}

.scrollOverlay {
  position: absolute;
  top: 0px;
  height: 100%;
  right: 15px;
  z-index: 2;
  pointer-events: none;
}

.scrollbar {
  position: relative;
  background: #262727;
  width: 4px;
  border-radius: 2px;
}
