.ClientSearch {
  position: relative;
}

.dropdown {
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-transform: capitalize;

  background: #ffffff;
  border: 1px solid rgba(232, 234, 233, 0.7);
  box-sizing: border-box;
  border-radius: 8px;

  img {
    margin-left: 10px;
  }
}

.searchMenu {
  position: absolute;
  top: 50px;
  width: 475px;
  display: flex;
  flex-direction: column;
}

.filterContainer {
  margin: 20px;
}

.filter {
  width: 100%;
  height: 50px;
  padding-left: 12px;
  box-sizing: border-box;
  background: #f8f9fc;
  border-radius: 10px;
  border: none;
  outline: none;
}

.clients {
  overflow-y: auto;
  position: relative;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.client {
  display: flex;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: white;
  width: 100%;

  .avatar {
    width: 34px;
    height: 34px;
    background: #ffffff;
    border: 1px solid rgba(232, 234, 233, 0.7);
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
  }

  .name {
    text-transform: capitalize;
  }
}

.selected {
  background: linear-gradient(0deg, #f8f9fc, #f8f9fc), #ffffff;
}

.scrollOverlay {
  position: absolute;
  top: 90px;
  height: 100%;
  right: 20px;
  z-index: 2;
  pointer-events: none;
}

.scrollbar {
  position: relative;
  background: #262727;
  width: 4px;
  border-radius: 2px;
}
