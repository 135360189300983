.Conversions {
  display: flex;
  flex-wrap: wrap;
}

.conversion {
  margin-right: 5px;
  margin-bottom: 5px;
}

.quantity {
  font-weight: 500;
  font-size: 11px;
  line-height: 100%;
  padding: 4.5px 6px;
  border-radius: 10px;
  min-width: 20px;
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
