.FoodlogChart {
  margin-left: -30px;
}

.conversionBar {
  margin-left: 30px;
  height: 36px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 3px;
  padding: 0 3px;
  align-items: center;
  background: #f8f9fc;
  border-radius: 10px;

  .conversion {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #88939d;
    cursor: pointer;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 5px;
    }
  }

  .selected {
    background: white;
    border-radius: 8px;
    color: #1c1e21;
  }
}

.plot,
.day {
  fill: #f8f9fc;
}

.day {
  pointer-events: all;
}

.day:hover {
  fill: #f1f3f9;
}

.dot,
.target,
.average,
.targetArea,
.totals,
.tooltip {
  pointer-events: none;
}

.totals {
  fill: none;
  stroke-width: 3px;
}

.average {
  stroke-width: 2px;
}

.target {
  stroke-width: 3px;
  stroke: #88939d;
  opacity: 0.1;
}

.targetArea {
  fill: #88939d;
  opacity: 0.1;
}

.tickX {
  font-size: 12px;
  color: #88939d;
}

.tickY {
  font-size: 10px;
  color: #88939d;
}

.tooltip {
  opacity: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: 10px;
  top: -1000;
  left: -1000;
  position: fixed;
  width: 220px;
  box-sizing: border-box;
  background: #29292a;
  color: white;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;

  > div {
    text-align: right;
    font-size: 14px;
    text-transform: capitalize;
  }

  .label {
    opacity: 0.7;
    text-align: left;
    font-size: 12px;
  }
}
