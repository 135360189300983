@import 'colors';

.Button {
	font-family: Work Sans;
	box-sizing: border-box;
	border-radius: 10px;
	height: 50px;
	padding: 0 15px;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.green {
	background-color: $primary;
	color: $white;
}

.white {
	background-color: $white;
	color: $black;
}

.red {
	background-color: $red;
	color: $white;
}

.border {
	border: 1px solid #eff1f0;
}

.full {
	width: 100%;
}

.loadingOverlay {
	opacity: 0.2;
	background: black;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
}

.spinner {
	margin-left: 10px;
	width: 15px;
	height: 15px;
}

.disabled {
	opacity: 0.5;
}
