.GoalsModal {
  width: 600px;
  height: calc(100vh - 100px);
  background: white;
  padding: 30px 0 0;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .header {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    display: flex;
    margin: 0 30px;
    margin-bottom: 30px;

    button {
      width: 30px;
      height: 30px;
      background: #fbfbfb;
      border-radius: 15px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.searchContainer {
  padding: 0 30px;
}

.goals {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
}
