@mixin noTouch {
  user-select: none;
  touch-action: none;

  * {
    user-select: none;
    touch-action: none;
  }
}

@mixin lastNoMargin {
  &:last-of-type {
    margin: 0;
  }
}

@mixin transition($params) {
  transition: $params;
  -webkit-transition: $params;
  -moz-transition: $params;
  -ms-transition: $params;
  -o-transition: $params;
}
