@import 'colors';

.Account {
  background: #212123;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.icon {
  height: 130px;
  margin-bottom: 50px;
}

.form {
  width: 375px;
}

.details {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.linkWrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;

  a {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Inter;
    letter-spacing: 0.04em;
  }
}
