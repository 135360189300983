.Textarea {
  width: 100%;
  flex: 1;
  background: #f8f9fc;
  border-radius: 10px;
  padding: 12px 15px;
  border: none;
  box-sizing: border-box;
  resize: none;
  font-size: 14px;
  line-height: 26px;
}
