@import 'colors';

.preview {
  display: grid;
  padding: 15px 5px;
  padding-right: 20px;
  grid-template-columns: 15px 60px 320px;
  text-align: left;
  background: white;
  color: $black;
  align-items: center;
}

.marker {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 5px;
}

.unread {
  background-color: $primary;
}

.avatar {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: white;
  border: 1px solid #eff1f0;
  border-radius: 50px;
  margin-right: 10px;
  color: $black;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.header {
  display: flex;
  margin-bottom: 5px;
}

.name {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  margin-right: auto;
}

.date {
  font-size: 12px;
  color: #88939d;
  display: flex;
  align-items: flex-end;
}

.lastMessage {
  font-size: 14px;
  line-height: 20px;
  color: #88939d;
}

.active {
  background: $primary;

  .name,
  .date,
  .lastMessage {
    color: white;
  }

  .date,
  .lastMessage {
    font-weight: 500;
  }
}
