@import 'colors';

.Admin {
  padding: 30px;
  height: 100vh;
  box-sizing: border-box;
}

.search {
  width: 500px;
  height: 50px;
  padding-left: 12px;
  box-sizing: border-box;
  background: #f8f9fc;
  border-radius: 10px;
  border: none;
  outline: none;
  margin-bottom: 20px;
}

.clients {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-bottom: 50px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.row {
  display: grid;
  grid-template-columns: 200px 100px 200px 200px 150px auto;
  grid-column-gap: 10px;
  font-size: 14px;
  cursor: pointer;
  color: #88939d;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #eff1f0;

  .name {
    text-transform: capitalize;
    color: $black;
  }

  .email {
    text-align: right;
  }
}

.header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #88939d;

  .name {
    color: #88939d;
  }
}
