.Modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: opacity 0.1s;
	-webkit-transition: opacity 0.1s;
	-moz-transition: opacity 0.1s;
	-ms-transition: opacity 0.1s;
	-o-transition: opacity 0.1s;

	&.show {
		opacity: 1;
	}

	&.noOverlay {
		background-color: transparent;
	}
}

.contents {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 100%;
	transition: top 0.2s 0.2s;
	-webkit-transition: top 0.2s 0.2s;
	-moz-transition: top 0.2s 0.2s;
	-ms-transition: top 0.2s 0.2s;
	-o-transition: top 0.2s 0.2s;

	&.show {
		top: 0;
	}
}
