.DayColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    font-size: 12px;
    line-height: 30px;
    color: #88939d;
    margin-bottom: 5px;
  }

  &:first-of-type {
    .data {
      border-left: 1px solid #f2f2f2;
      border-top-left-radius: 10px;
    }
  }

  &:last-of-type {
    .data {
      border-top-right-radius: 10px;
    }
  }
}

.analytics {
  width: 100%;
  max-width: 150px;
  height: 62px;
  background: #f8f9fc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .bar {
    width: 120px;
    height: 6px;
    background: #1c1e21;
    opacity: 0.1;
    border-radius: 10px;
  }
}

.data {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}

.meals {
  flex: 1;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .empty {
    font-size: 12px;
    color: #88939d;
    margin: auto 0;
  }
}

.trackers {
  display: grid;
  grid-row-gap: 10px;
  align-items: center;
  margin: 15px;
  padding: 15px;
  background: #f8f9fc;
  border-radius: 10px;
  cursor: pointer;

  .tracker {
    display: flex;
    align-items: center;
    font-size: 12px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .noValue {
    color: #88939d;
  }
}

.Meal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 15px;
  background: #f8f9fc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  .header {
    align-self: stretch;
    display: flex;
    margin-bottom: 10px;

    .mealType {
      flex: 1;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #1c1e21;
      text-transform: capitalize;
    }

    .time {
      font-size: 12px;
      line-height: 15px;
      text-align: right;
    }
  }

  .foods {
    font-size: 12px;
    line-height: 15px;
    color: #88939d;
    margin-bottom: 13px;
  }

  .commentMarker {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 10px;
    height: 10px;
    background: #1c1e21;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
}

.MealDetails {
  width: 435px;
  padding: 30px;
  position: absolute;
  transform: translate(0, -50%);
  box-sizing: border-box;
  cursor: auto;

  .mealType {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #1c1e21;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .time {
    font-size: 14px;
    line-height: 100%;
    color: #1c1e21;
    margin-bottom: 10px;
  }

  .created {
    font-size: 14px;
    line-height: 100%;
    color: #88939d;
    margin-bottom: 30px;
  }

  .foods {
    margin-bottom: 20px;
  }

  .comment {
    margin-top: 30px;
    background: #ffffff;
    box-shadow: inset 4px 0px 0px #edf0f7;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #1c1e21;
    padding-left: 20px;
  }
}

.Food {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 17px 18px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  margin-bottom: 10px;

  .noImage,
  .image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    grid-row: 1/3;
    background: #f8f9fc;
  }

  .name {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #1c1e21;
  }

  .quantity {
    font-size: 14px;
    line-height: 18px;
    color: #88939d;
  }
}
