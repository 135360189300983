.UpdateClientModal {
  width: 600px;
}

.form {
  margin-top: 50px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
