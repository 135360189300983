.Section {
}

.header {
  display: flex;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  button {
    margin-left: auto;
    background: none;

    img {
      opacity: 0.5;
    }
  }
}

.ListItem {
  border-top: 1px solid #eff1f0;
  padding: 15px 0;
  font-size: 14px;
  line-height: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;

  &:last-of-type {
    border-bottom: 1px solid #eff1f0;
  }

  .category {
    margin-left: auto;
    text-transform: capitalize;
    color: #88939d;
  }
}
