@import 'colors';

.ResourceModal {
  width: 800px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  border-radius: 10px;
  background: white;

  .cover {
    position: relative;

    img {
      width: 100%;
      height: 450px;
      object-fit: cover;
    }
  }

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.info {
  padding: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: $black;

  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
  }
}

.cta {
  font-family: Work Sans;
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  padding: 0 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: $white;
}
