@import 'colors';

.Dialogue {
  width: 440px;
  padding: 30px;
}

.nameInput {
  margin-top: 30px;
}

.deleteLink {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $red;
    cursor: pointer;
  }
}

.deleteInfo {
  margin-top: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  .name {
    font-weight: 600;
  }
}

.buttons {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
