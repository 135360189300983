@import 'colors';

.ResourceCard {
  background: #f8f9fc;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  color: $black;
}

.coverImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.info {
  padding: 15px 20px;
  padding-bottom: 25px;
}

.name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}

.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
