.Select {
	position: relative;
	width: fit-content;
	display: flex;
	align-items: center;
	height: 50px;
	background: #f8f9fc;
	box-sizing: border-box;
	border-radius: 10px;
	cursor: pointer;
	font-size: 14px;
	padding: 0 15px;

	&.full {
		width: 100%;
	}

	.label {
		font-size: 14px;
		color: #88939d;
		margin-right: 5px;
	}

	.value {
		margin-right: auto;
	}

	.icon {
		height: 20px;
		user-select: none;
	}
}

.Dropdown {
	position: absolute;
	width: 100%;
	max-height: 300px;
	overflow-y: auto;
	top: 50px;
	left: 0;
	padding: 15px 0;
	background: white;
	box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	box-sizing: border-box;
	z-index: 50;
}

.Dropdown.openUp {
	top: unset;
	bottom: 50px;
}

.Option {
	font-style: normal;
	font-weight: normal;
	padding: 10px 20px;
	cursor: pointer;

	&:hover {
		background: #eee;
	}
}
