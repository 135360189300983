@import 'mixins';
@import 'colors';

.Client {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
}

.header {
  box-shadow: 0px 1px 0px #edeef1;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.left {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tabnav {
  background: #f8f9fc;
  border-radius: 9px;
  padding: 3px;
  display: flex;

  .navLink,
  .activeNav {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 8px;
    padding: 0 12px;
    color: #88939d;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.01em;

    @include lastNoMargin;
  }

  .activeNav {
    background: white;
    color: $black;
  }
}

.currentTargets {
  position: relative;

  .targetsPopup {
    width: 400px;
    right: 0;
    top: 50px;
  }

  .targets {
    border: none;
  }
}

.chat {
  margin-left: 10px;
  position: relative;

  .unread {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: $primary;
  }
}

.contentContainer {
  overflow-y: auto;
}

.content {
  padding: 20px 20px 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
