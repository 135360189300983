.StatsTable {
  padding: 10px 20px;
  border: 1px solid rgba(232, 234, 233, 0.7);
  box-sizing: border-box;
  border-radius: 10px;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  .row:nth-child(2) {
    border-top: 1px solid #eff1f0;
    border-bottom: 1px solid #eff1f0;
  }

  .label {
    font-size: 14px;
    color: #88939d;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .value {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 130%;
    text-transform: capitalize;
  }
}
