.SelectList {
  > div:first-of-type {
    margin-left: 30px;
  }
}

.SelectItem {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fc;
  }
}

.content {
  display: flex;
  align-items: center;
  user-select: none;
}

.checkbox {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-right: 10px;
  opacity: 0.75;
}
