@import 'colors';

.CollectionsList {
  flex-basis: 260px;
  height: 100%;
  box-shadow: 1px 0px 0px #edeef1;
  padding: 50px 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.section {
  margin-bottom: 30px;
}

.header {
  height: 44px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eff1f0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.ListItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: $black;

  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
}

.selected {
  color: white;
  background: $primary;
  font-weight: 500;
}
