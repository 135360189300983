@import 'colors';

.Card {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(199, 200, 202, 0.6);
  border-radius: 15px;
}

.header {
  display: flex;
  align-items: center;

  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: $black;
    margin-right: auto;
  }

  .closeButton {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #fbfbfb;
  }
}
