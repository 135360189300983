.HeaderButton {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;

  background: #ffffff;
  border: 1px solid rgba(232, 234, 233, 0.7);
  box-sizing: border-box;
  border-radius: 8px;
}
