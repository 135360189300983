.AveragesChart {
  margin-bottom: 30px;
}

.plot {
  fill: #f8f9fc;
}

.zero {
  stroke: #e6e9ed;
  stroke-width: 1px;
}

.average {
  stroke-width: 2px;
}

.sd {
  opacity: 0.25;
}

.tickX {
  font-size: 12px;
  text-transform: capitalize;
  color: #88939d;
}

.tooltip {
  opacity: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: 10px;
  top: -1000;
  left: -1000;
  position: fixed;
  width: 200px;
  box-sizing: border-box;
  background: #29292a;
  color: white;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;
  pointer-events: none;

  > div {
    text-align: right;
    font-size: 14px;
    text-transform: capitalize;
  }

  .label {
    opacity: 0.7;
    text-align: left;
    font-size: 12px;
  }
}
