.DropdownButton {
  background: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #1c1e21;
  display: flex;
  align-items: center;
  padding: 0;

  img {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
}
