.ScheduledMessagesList {
  position: absolute;
  right: 0;
  top: 50px;
  padding: 20px 0;
  width: 420px;
}

.noMessages {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1c1e21;
  text-align: center;
}

.button {
  padding: 0 20px;
}

.newMessage {
  margin-top: 30px;
  margin-bottom: 10px;
}

.Message {
  padding: 15px 20px;
  cursor: pointer;

  &:hover {
    background: #f8f9fc;
  }
}

.datetime {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}

.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #88939d;
}
