.react-calendar {
  width: 435px;
  padding: 30px;
  background: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 38px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 38px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #0a0909;
}
.react-calendar__navigation__label {
  pointer-events: none;
}
.react-calendar__navigation button {
  background: none;
}

.react-calendar__month-view__weekdays {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #1c1e21;
}

.react-calendar__month-view__days__day {
  color: #221f20;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #88939d;
}
