.TargetsPopup {
  width: 475px;
  box-sizing: border-box;
  background: white;
  padding: 30px;
  top: -50px;
  left: 160px;
}

.left {
  left: -485px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}

.date {
  font-size: 14px;
  margin-bottom: 30px;
}

.targets {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.Target {
  height: 71px;
  border-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .name {
    font-size: 15px;
    text-transform: capitalize;
  }

  .status {
    align-items: center;

    img {
      height: 20px;
    }
  }

  .label {
    font-size: 12px;
  }

  .noTarget {
    width: 32px;
    border-bottom: 1px solid #c8cdd1;
    align-self: flex-end;
  }
}

.white {
  color: white;

  .label {
    color: white;
    opacity: 0.7;
  }
}

.progressBar {
  width: 100%;
  height: 6px;
  background: #c8cdd1;
  border-radius: 3px;
  width: 76px;
  overflow: hidden;

  .progress {
    height: 100%;
  }
}
