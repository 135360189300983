@import 'colors';

.chat {
}

.chatContainer {
  flex-direction: column;
}

.channelsList {
  overflow: auto;
  border-right: 1px solid #edeef1;
  background: white !important;

  > div {
    background-color: white;

    > div {
      padding: 0 !important;
    }
  }
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .showAll {
    cursor: pointer;
  }
}

.search {
  padding: 12px 0px 12px 15px;
  background: #f8f9fc;
  border-radius: 10px;
  box-sizing: content-box;
  border: none;
  flex: 1;

  &:focus {
    outline: 1px solid $black;
  }

  font-size: 14px;
  line-height: 26px;
}

.massMessages {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #00cc77;
  border: 1px solid #eff1f0;
  border-radius: 8px;
  margin-left: 10px;

  img {
    width: 24px;
    height: 24px;
  }
}

.empty {
  margin: 10px 20px;
  font-size: 14px;
  color: #88939d;
}

.channelHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 80px;
  background: white;
  border-bottom: 1px solid #edeef1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;

  .buttons {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
  }
}

.blank {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    opacity: 0.15;
  }
}

.numActiveMessages {
  margin-left: 5px;
  color: #88939d;
}
