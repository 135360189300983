.TrackersModal {
  width: 373px;
  max-height: calc(100vh - 100px);
  padding: 30px;
  background: white;
  border-radius: 15px;
  overflow-y: auto;
}

.title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}

.date {
  font-size: 14px;
}

.header {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.entries {
  border-radius: 12px;
  border: 1px solid #eff1f0;
}

.entry,
.noEntry {
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  padding: 20px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.noEntry {
  color: #88939d;
}

.movement {
  border-bottom: 1px solid #eff1f0;
  padding: 20px;

  &:last-of-type {
    border-bottom: none;
  }
}

.name {
  display: flex;
  align-items: center;
}

.activity {
  text-transform: capitalize;
}

.for {
  font-weight: 400;
  font-size: 14px;
  opacity: 0.7;
  margin: 0 5px;
}

.time {
  font-weight: 400;
  font-size: 14px;
}

.comment {
  margin-top: 10px;
  font-size: 14px;
  line-height: 140%;
  font-style: italic;
}

.note {
  padding: 20px;
  font-size: 14px;
  line-height: 140%;
  font-style: italic;
}
