.conversions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  padding: 20px;
  border: 1px solid rgba(232, 234, 233, 0.7);
  box-sizing: border-box;
  border-radius: 10px;
}

.conversion {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;

  .color {
    font-weight: 500;
    font-size: 16px;
    color: white;
    min-width: 35px;
    height: 35px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border-radius: 35px;
    margin-right: 10px;
  }

  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}
