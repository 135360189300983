.Accordion {
}

.header {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.arrowContainer {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.arrow {
  transform: rotateZ(-90deg);
  transition: transform 0.1s;
}

.open {
  transform: rotateZ(0);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
