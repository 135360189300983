@import 'colors';

.Form {
}

.FormTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 40px;
	letter-spacing: -0.01em;
	width: 100%;
	text-align: center;
	margin-bottom: 50px;
}

.FormInputLabel {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 32px;
	margin-bottom: 5px;
}

.FormInputGroup {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;

	input {
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		height: 50px;
		padding: 0 15px;
		box-sizing: border-box;
		background: #f8f9fc;
		border-radius: 10px;
		border: none;
		display: flex;
		align-items: center;
	}
}

.FormSelectGroup {
	margin-bottom: 30px;
}

.FormButton {
}

.FormLink {
	display: inline-block;
	color: $primary;
	margin-bottom: 10px;
}

.FormInfo {
	margin-bottom: 50px;
	text-align: center;
	color: rgba(0, 0, 0, 0.35);
}

.FormError {
	text-align: center;
	margin-top: 10px;
	color: #db5353;
	line-height: 140%;
}

.Checkbox {
	position: relative;
	display: block;
	flex: 0 0 26px;
	width: 26px;
	height: 26px;
	margin-right: 10px;

	&.large {
		width: 50px;
		height: 50px;
		flex: 0 0 50px;
		border-radius: 4px;
		overflow: hidden;

		.check {
			border: none;
		}

		input:checked ~ .check::after {
			left: 21px;
			top: 16px;
		}
	}

	input {
		position: absolute;
		opacity: 0;

		&:checked ~ .check {
			background-color: $black;
			border-color: $black;
			-webkit-transform: rotate(0deg) scale(1);
			-ms-transform: rotate(0deg) scale(1);
			transform: rotate(0deg) scale(1);
		}

		&:checked ~ .check::after {
			-webkit-transform: rotate(45deg) scale(1);
			-ms-transform: rotate(45deg) scale(1);
			transform: rotate(45deg) scale(1);
			opacity: 1;
			left: 7px;
			top: 2px;
			width: 5px;
			height: 12px;
			border: solid white;
			border-width: 0 2px 2px 0;
			border-radius: 0;
		}
	}

	.label {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.05);

		font-family: Jost, Arial;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
	}

	.check {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		border: 2px solid rgba(0, 0, 0, 0.15);
		box-sizing: border-box;
		border-radius: 4px;
		cursor: pointer;

		&::after {
			position: absolute;
			content: '';
			left: 12px;
			top: 12px;
			height: 0px;
			width: 0px;
			border-radius: 5px;
			border: solid #009bff;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(0deg) scale(0);
			-ms-transform: rotate(0deg) scale(0);
			transform: rotate(0deg) scale(0);
			opacity: 1;
			transition: all 0.3s ease-out;
			-webkit-transition: all 0.1s ease-out;
			-moz-transition: all 0.1s ease-out;
			-ms-transition: all 0.1s ease-out;
			-o-transition: all 0.1s ease-out;
		}
	}
}
