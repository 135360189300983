@import 'colors';

* {
  font-family: 'Inter';
}

html,
body {
  width: 100%;
  height: 100%;
}

div#app-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

button {
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

a {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}
