.Profile {
  padding: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
}

.col {
  > div {
    margin-bottom: 50px;
  }
}
