@import 'colors';

.Collection {
  flex: 1;
  padding: 50px;
  max-height: 100%;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-right: auto;
  }

  .searchContainer {
    width: 200px;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 20px;
    padding-left: 15px;
    padding-right: 10px;
    grid-column-gap: 10px;
    align-items: center;
    box-sizing: border-box;
    background: #f8f9fc;
    border-radius: 10px;
  }

  .search {
    width: 100%;
    padding-right: 0;
    background: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    border: none;
    outline: none;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.resourcesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

.collectionSection {
  color: $black;
  margin-bottom: 40px;

  .collectionName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
}

.subheader {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff1f0;
  margin-bottom: 15px;

  .count {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #88939d;
  }
}

.noBorder {
  border: none;
}
