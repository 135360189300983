.marker {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #00cc77;
  bottom: 4px;
}

.tile {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 24px;
  position: relative;
  background: none;
}

.navLabel {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #0a0909;

  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}
