@import 'colors';

.modal {
  z-index: 100;
}

.ScheduleModalMass {
  width: 500px;
}

.recipientsContainer {
  margin-top: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background: #f8f9fc;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  img {
    width: 14px;
    height: 14px;
    margin-left: auto;
  }

  .recipients {
    color: #88939d;
  }

  .hasRecipients {
    color: $black;
  }
}

.datetime {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 20px;
}

.error {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #db5353;
  text-align: center;
}

.message {
  margin-top: 20px;
  margin-bottom: 30px;
  height: 150px;
}

.deleteWrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #db5353;
  cursor: pointer;
}
